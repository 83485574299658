import {ComponentDivWidth, ComponentEuiPanel} from 'Component'
import {TypeHttpPayload, TypeNav, TypeNavControlActionType} from 'Type'
import ComponentNavAction from "../ComponentNavAction";

type Props<T> = {
    width? :number
    title :string
    isTitleVerb? :boolean
    nav :TypeNav<T>
    actionType :TypeNavControlActionType
    onSuccess :(res :T, data :TypeHttpPayload) => Promise<void>
}

const ComponentNavActionPanel = <T, >({width, title, isTitleVerb, nav, actionType, onSuccess} :Props<T>) => {
    return (
      <ComponentDivWidth width={width || 400}>
        <ComponentEuiPanel borderRadius={'m'} hasBorder>
            <ComponentNavAction title={title} isTitleVerb={isTitleVerb} nav={nav} actionType={actionType} onSuccess={onSuccess}/>
        </ComponentEuiPanel>
      </ComponentDivWidth>
    )
}

export default ComponentNavActionPanel