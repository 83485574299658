import {
    ComponentEuiButtonEmpty, ComponentEuiDescriptionList,
    ComponentEuiFlexGroup,
    ComponentEuiFlexItem,
    ComponentEuiHeader,
    ComponentEuiHeaderSection,
    ComponentEuiHeaderSectionItem,
    ComponentEuiPageSection, ComponentEuiTableBasic,
    ComponentNavActionModalButton,
    ComponentNavAuthToolTip,
    ComponentNavMetaPageSectionHeader
} from 'Component'
import {useLocation, useNavigate} from "react-router-dom";
import {
    TypeEntityLinkTo, TypeEuiPropsIconType,
    TypeNav, TypeNavControlAction, TypeNavControlActionType,
    TypeNavControlResource,
    TypeNavHttpControl,
    TypeNavMeta
} from 'Type'
import { ModulePageHeaderBreadcrumbs } from 'Module'
import {HookHttpUrl, HookNavNavigateResource} from 'Hook'
import { FORMAT_PAGE_URLS } from 'Format'
import { UtilRoute } from 'Util';

const ActionIconRecord :Record<TypeNavControlActionType, TypeEuiPropsIconType> = {
    delete: 'trash',
    post: 'plusInCircle',
    put: 'documentEdit',
    patch: 'documentEdit'
}

type Props = {
    pageControl: TypeNavHttpControl<TypeEntityLinkTo>
}

const meta :TypeNavMeta = {
    title: 'Welcome to the Morph Cloud API Navigator!',
    description: ['The Navigator is a single web page that allows any user to operate the entire Morph Cloud API.',
        'From any part of Morph Cloud you can switch to The Navigator to understand the corresponding API. And vice versa.']
}

type PropsResourceButton = {
    resource :TypeNavControlResource
}

const ResourceButton = ({ resource } :PropsResourceButton) => {
    const onClick :() => void = HookNavNavigateResource(resource, FORMAT_PAGE_URLS.nav)
    const unauhorized :string | undefined = resource.auth.message
    return <ComponentEuiButtonEmpty onClick={onClick} isDisabled={Boolean(unauhorized)}>{resource.meta.title}</ComponentEuiButtonEmpty>
}

type PropsActionButton = {
    nav :TypeNav<any>
    action :TypeNavControlAction
    onSuccess :(action: TypeNavControlActionType, entity? :any) => void
}

const ActionButton = ({ nav, action, onSuccess } :PropsActionButton) => {
    return <ComponentNavActionModalButton nav={nav} actionType={action.action} buttonType={'empty'} color={(action.action === 'delete') ? 'danger' : 'primary'}
    entityName={action.action} buttonTitle={action.action} iconType={ActionIconRecord[action.action]} onSuccess={(entity? :any) => { onSuccess(action.action, entity) }}/>
}

const PageNavigatorBody = ({ pageControl } :Props) => {

    const navigate = useNavigate()
    const location = useLocation()

    const pageNav :TypeNav<TypeEntityLinkTo> | undefined = pageControl.res?.data

    if (!pageNav) return null

    const data :any[] = (pageNav.data.page) ? pageNav.data.page.content : (pageNav.data.list) ? pageNav.data.list : (pageNav.data.entity) ? [pageNav.data.entity] : []

    const isEntity :boolean = Boolean(pageNav.data.entity)

    const { url } = HookHttpUrl(pageControl?.res?.data?.path, [{type: 'context', values: ['true']}])

    const onActionSuccess = (action :TypeNavControlActionType, entity? :any) => {
        if (action === 'put' || action === 'patch') pageControl.submit()
        if (action === 'post' && entity && entity['linkTo']) navigate(`${FORMAT_PAGE_URLS.nav}${entity['linkTo']}`)
        if (action === 'delete') navigate(UtilRoute.backOne(location.pathname))
    }

    return <>
        <ComponentNavMetaPageSectionHeader meta={meta} bottomBorder={'extended'}/>
        <ComponentEuiHeader>
            <ComponentEuiHeaderSection grow>
                <ComponentEuiHeaderSectionItem>
                    <ModulePageHeaderBreadcrumbs control={pageControl} prependPath={FORMAT_PAGE_URLS.nav}/>
                </ComponentEuiHeaderSectionItem>
            </ComponentEuiHeaderSection>
        </ComponentEuiHeader>
        <ComponentNavMetaPageSectionHeader meta={pageNav?.meta} bottomBorder={'extended'}/>
        <ComponentEuiPageSection bottomBorder={'extended'} paddingSize={'m'}>
            {url && <ComponentEuiDescriptionList listItems={[{title: "URL", description: url}]} type={"inline"}/>}
        </ComponentEuiPageSection>
        <ComponentEuiPageSection bottomBorder={'extended'} paddingSize={'s'}>
            <ComponentEuiFlexGroup gutterSize={'s'} justifyContent={'spaceBetween'}>
                <ComponentEuiFlexItem>
                    <ComponentEuiFlexGroup gutterSize={'none'}>
                        {Object.values(pageNav?.control.resource ?? []).map((resource :TypeNavControlResource) =>
                            <ComponentEuiFlexItem key={resource.linkTo}>
                                <ComponentNavAuthToolTip auth={resource.auth}><ResourceButton resource={resource}/></ComponentNavAuthToolTip>
                            </ComponentEuiFlexItem>)}
                    </ComponentEuiFlexGroup>
                </ComponentEuiFlexItem>
                <ComponentEuiFlexItem>
                    <ComponentEuiFlexGroup gutterSize={'none'}>
                        {Object.values(pageNav?.control.action ?? []).map((action :TypeNavControlAction) =>
                            <ComponentEuiFlexItem key={action.action}>
                                <ComponentNavAuthToolTip auth={action.auth}><ActionButton nav={pageNav} action={action} onSuccess={onActionSuccess}/></ComponentNavAuthToolTip>
                            </ComponentEuiFlexItem>)}
                    </ComponentEuiFlexGroup>
                </ComponentEuiFlexItem>
            </ComponentEuiFlexGroup>
        </ComponentEuiPageSection>
        <ComponentEuiPageSection paddingSize={'none'}>
            {(data && data.length) ? <ComponentEuiTableBasic items={data}
                 columns={Object.keys(data[0]).map((field :string) => ({field: field, name: field}))}
                 itemId={'linkTo'} rowProps={(isEntity) ? undefined : (item: any) => ({onClick: () => {navigate(`${FORMAT_PAGE_URLS.nav}${item.linkTo}`)}})}/> : null }
        </ComponentEuiPageSection>
    </>
}

export default PageNavigatorBody