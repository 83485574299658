import { ComponentEuiButton, ComponentEuiCallout, ComponentNavActionModal } from 'Component/index'
import { HookControlSwitch } from 'Hook'

import {TypeControlSwitch, TypeEuiPropsIconType, TypeNav, TypeNavControlAction, TypeNavControlActionType } from 'Type'

type Props<T> = {
    nav :TypeNav<T>
    actionType :TypeNavControlActionType
    iconType :TypeEuiPropsIconType
    color :'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'text'
    title :string
    onSuccess? :(entity? :any) => void
    fill? :boolean
}

const ComponentNavSidebarButtonAction = <T,>({ nav, actionType, color, iconType, title, onSuccess, fill } :Props<T>) => {

    const switchControl :TypeControlSwitch = HookControlSwitch()

    const action :TypeNavControlAction | undefined = nav.control.action[actionType]

    const onSuccessInner = (entity? :any) => {
        switchControl.turnOff()
        if (onSuccess) onSuccess(entity)
    }

    if (!action) return null

    return <>
        <ComponentEuiButton color={color} iconType={iconType} onClick={switchControl.turnOn} isDisabled={!action.auth.authorized} fullWidth fill={fill}>{ title }</ComponentEuiButton>
        {action.auth.message && <ComponentEuiCallout color={'warning'} title={action.auth.message} iconType={'alert'}/>}
        {(switchControl.value) ? <ComponentNavActionModal nav={nav} actionType={actionType} onClose={switchControl.turnOff} title={title} onSuccess={onSuccessInner}/> : null}
    </>
}

export default ComponentNavSidebarButtonAction