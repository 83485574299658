import {ComponentEuiModal} from 'Component'
import {TypeHttpPayload, TypeNav, TypeNavControlActionType} from 'Type'
import ComponentNavAction from "../ComponentNavAction";

type Props<T> = {
    title: string
    isTitleVerb?: boolean
    nav: TypeNav<T>
    actionType: TypeNavControlActionType
    onSuccess: (entity: T, data: TypeHttpPayload) => any
    onClose: () => void
}

const ComponentNavActionModal = <T, >({title, isTitleVerb, nav, actionType, onClose, onSuccess}: Props<T>) => {
    return <ComponentEuiModal onClose={onClose}>
        <ComponentNavAction title={title} isTitleVerb={isTitleVerb} nav={nav} actionType={actionType}
                            onSuccess={onSuccess}/>
    </ComponentEuiModal>

}

export default ComponentNavActionModal