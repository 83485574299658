import {
    ComponentEuiDataGrid,
    ComponentNavPageFilterTablePopover
} from 'Component'
import {ContextTableData} from 'Context'
import {
    HookModuleTableColumn,
    HookModuleTableDataCellRenderer,
    HookModuleTableDataRowSelect,
    HookPageLocation
} from 'Hook'
import {
    ModuleTableColumnControlSystemToggle,
    ModuleTableColumnFilterModal,
    ModuleTableDataContext,
    ModuleTableDataRowSelectedButton
} from 'Module'
import React, {useContext, useEffect, useRef} from 'react'
import {
    TypeContextTableData,
    TypeEntityRow,
    TypeEuiPropsDataGridControlColumn,
    TypeEuiPropsDataGridRef, TypeEuiPropsDataGridToolbarVisibilityOptions,
    TypeModuleTableColumnControl,
    TypeModuleTableDataCellRenderer,
    TypeModuleTableDataProps,
    TypeNav
} from 'Type'


const ModuleTableDataTableInner = ({dataControl, columnControl, fieldNameMap}: TypeModuleTableDataProps) => {

    const dataGridRef = useRef<TypeEuiPropsDataGridRef | null>(null);

    const {location} = HookPageLocation()

    const closeCellPopover: () => void = () => {
        if (dataGridRef.current) {
            dataGridRef.current.closeCellPopover()
        }
    }

    const dataNav: TypeNav<TypeEntityRow> | undefined = dataControl.res?.data
    const dataContext: TypeContextTableData = useContext(ContextTableData)
    const {selectedRows, selectControl, clearSelected} = HookModuleTableDataRowSelect({data: dataContext.data})
    const {
        gridColumns,
        columnVisibility,
        leadingControlColumns,
        trailingControlColumns,
        toFilterColumn,
        clearToFilterColumn,
        sorting
    }: TypeModuleTableColumnControl = HookModuleTableColumn(dataNav!, columnControl, dataContext.data)

    const {renderCellValue, renderCellPopover}: TypeModuleTableDataCellRenderer = HookModuleTableDataCellRenderer()

    useEffect(() => {
        closeCellPopover()
    }, [location])

    const onDeleteSelected = () => {
        console.log('On delete selected')
        dataControl.submit()
        clearSelected()
    }

    const joinedLeadingControlColumns: TypeEuiPropsDataGridControlColumn[] = [selectControl, ...(leadingControlColumns || [])]

    const toolbarVisibility: TypeEuiPropsDataGridToolbarVisibilityOptions = {
        additionalControls: <>
            <ModuleTableColumnControlSystemToggle/>
            <ModuleTableDataRowSelectedButton selectedRows={selectedRows} onComplete={onDeleteSelected}/>
            <ComponentNavPageFilterTablePopover fieldNameMap={fieldNameMap}/>
        </>
    }

    const rowCount :number = dataContext.data.length
    return <>
        <ComponentEuiDataGrid columns={gridColumns} rowCount={rowCount} columnVisibility={columnVisibility}
                          toolbarVisibility={toolbarVisibility} renderCellValue={renderCellValue}
                          leadingControlColumns={joinedLeadingControlColumns} trailingControlColumns={trailingControlColumns}
                          renderCellPopover={renderCellPopover} sorting={sorting} forwardRef={dataGridRef} />
        { dataNav && toFilterColumn && <ModuleTableColumnFilterModal nav={dataNav} column={toFilterColumn} fieldNameMap={fieldNameMap} onClose={clearToFilterColumn}/> }
    </>

}

const ModuleTableDataTable = ({ dataControl, columnControl, fieldNameMap, updatesControl, toLoad } :TypeModuleTableDataProps) => {
    return <ModuleTableDataContext dataControl={dataControl} columnControl={columnControl} updatesControl={updatesControl}>
            <ModuleTableDataTableInner dataControl={dataControl} columnControl={columnControl} fieldNameMap={fieldNameMap} updatesControl={updatesControl} toLoad={toLoad}/>
    </ModuleTableDataContext>
}

export default ModuleTableDataTable